import React, { useLayoutEffect, useRef, useState } from "react";
import "./Mypage.css";
import depthHome from "../../images/depth_home.png";
import profileImg from "../../images/profile_img.png";
import deleteIcon from "../../images/delete.png";
import pictureImg from "../../images/picture_img.png";
import close from "../../images/close.png";
import back from "../../images/back.png";
import { Link, useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "./Sidebar";
import Modal from "../../components/Modal";
import $ from "jquery";
import {
  changeDateFormat,
  CheckPw,
  deleteCookie,
  getCookie,
  getTodayDateNTime,
  checkSpecial,
  checkOnlyNumber,
  strDecoding,
  strEncoding
} from "../../Common/lib";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { auth } from "../../_actions/user_action";
import MobileBottomNav from "../Footer/MobileBottomNav";

function MypageModify() {
  const x_auth = getCookie("x_auth");
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  let history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const [modal, setModal] = useState({
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
  });

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  // 프로필 이미지 관리용 state
  const [img, setImg] = useState({});
  //회원 정보
  const [member, setMember] = useState({});
  //핸드폰 인증정보
  // const [telAuth, setTelAuth] = useState({});
  // 광고성정보수신 허용 목록
  const [advList, setAdvList] = useState([]);
  // 광고성 정보 수신 개별 체크 관리
  const [advCheckVal, setAdvCheckVal] = useState([false, false, false, false]);
  // 광고성 정보 수신 여부 클릭에 따른 모달창 text 변경
  const [agreeMessage, setagreeMessage] = useState("");
  const [rds, setRds] = useState([false, true]);

  const dispatch = useDispatch();

  const [isLoginAuth, setIsLoginAuth] = useState(false);
  const [memname, setMemname] = useState(null);
  const [profilePath, setProfilePath] = useState("");

  // 내정보수정 페이지 접속 시
  useLayoutEffect(() => {
    dispatch(auth()).then((res) => {
      setIsLoginAuth(res.payload.isLogin);
      setMemname(res.payload.memname);
      setProfilePath(res.payload.profilesImg);
    });

    let url = "/api/mypage/modify";
    // var x_auth = localStorage.getItem("x_auth");
    let body = {
      x_auth: x_auth,
    };
    axios.post(url, body).then((res) => {
      if (res.data.result === "success") {
        if (res.data.profile !== "false") {
          setImg(res.data.profile);
        }
        if (res.data.member !== "false") {
          let member = res.data.member;
          member.memid = strDecoding(member.memid);
          member.memname = strDecoding(member.memname);
          member.memrealname = strDecoding(member.memrealname);
          member.memjointype = strDecoding(member.memjointype);
          member.cellno = strDecoding(member.cellno);

          // 광고성 정보 수신 동의 상태일 때
          let adl = res.data.member.advertising;
          if (adl !== "false") {
            setRds([true, false]);
            let acv = [...advCheckVal];
            // 해당되는 칭구 check해주기
            adl.map((val) => {
              return (acv[val] = true);
            });
            setAdvCheckVal(acv);
            setAdvList(adl);
          } else {
            setRds([false, true]);
            setAdvList([]);
          }
          setMember(member);
        }
      } else {
        alert("정보를 불러오는데 실패하였습니다.");
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  let allAgreeControl = (how) => {
    let changeList = [];
    let ac = [...advCheckVal];
    if (how === "add") {
      ac.forEach((val, idx) => {
        let idx_string = idx.toString();
        ac[idx] = true;
        changeList.push(idx_string);
      });
      setAdvList(changeList);
      setAdvCheckVal(ac);
      return changeList;
    } else if (how === "delete") {
      ac.forEach((val, idx) => {
        ac[idx] = false;
      });
      setAdvList([]);
      setAdvCheckVal(ac);
      return "false";
    }
  };

  // 광고성 정보 수신 동의 미동의 버튼 관리
  const handleAgree = () => {
    let data, rs;

    // 라디오 버튼 체크
    if ($("#rd2").prop("checked")) {
      setRds([false, true]);
      setagreeMessage("마케팅활용 거부하셨습니다.");

      rs = allAgreeControl("delete");
      data = { advertising: rs };
    } else {
      setRds([true, false]);
      setagreeMessage("광고성 정보 동의하셨습니다.");

      rs = allAgreeControl("add");
      data = { advertising: rs };
    }
    advAxios(data, true);
  };

  // 회원 탈퇴 클릭시 > 정말 탈퇴 모달 또 띄움...
  const doubleModal = () => {
    if (reason && reasonChk.reasonChk5 === "") {
      alert("기타 이유를 입력해주세요.");
    } else {
      setModal({ ...modal, modal3: false, modal4: true });
    }
  };
  const [reasonChk, setReasonChk] = useState({
    reasonChk5: "",
  });
  const chkReason = (e) => {
    if (e.target.name === "reasonChk5") {
      setReasonChk({ ...reasonChk, [e.target.name]: e.target.value });
    } else {
      if (e.target.checked) {
        setReasonChk({ ...reasonChk, [e.target.name]: e.target.value });
      } else {
        setReasonChk({ ...reasonChk, [e.target.name]: "" });
      }
    }
  };

  // 회원탈퇴 > 기타 체크 시 textarea 표시
  const [reason, setReason] = useState(false);
  const checkEtc = (e) => {
    if ($("#withdrawal5").prop("checked")) {
      setReason(true);
    } else {
      setReason(false);
    }
  };

  // 체크박스 체크에 따른 반응
  const handleNotice = (e) => {
    let name = ["앱푸시", "이메일", "문자", "알림톡"];
    let i = Number(e.target.id.slice(-1)) - 1;
    // id는 agreeReceive1 과 같이 되어있음

    let al = [...advList];
    let acv = [...advCheckVal];

    let data;
    switch (e.target.checked) {
      case true:
        if (window.confirm(name[i] + "수신에 동의하시겠습니까?")) {
          acv[i] = true;
          al.push(i.toString());
          data = { advertising: al };

          setAdvList(al);

          advAxios(data);
        } else {
          acv[i] = false;
        }
        setAdvCheckVal(acv);
        break;

      case false:
        if (window.confirm(name[i] + "수신을 취소하시겠습니까?")) {
          acv[i] = false;
          let filterAl = al.filter((num) => num !== i.toString());
          if (filterAl.length > 0) {
            data = { advertising: filterAl };
          } else {
            data = { advertising: "false" };
            setRds([false, true]);
          }
          setAdvList(filterAl);

          advAxios(data);
        } else {
          acv[i] = true;
        }
        setAdvCheckVal(acv);
        break;
      default:
        break;
    }
  };

  // 광고성 정보수신 변경 용 백엔드 통신
  let advAxios = async (data, all = null) => {
    let url = "/api/mypage/modify/modifyAdvertising";
    // var x_auth = localStorage.getItem("x_auth");
    data.x_auth = x_auth;
    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        // IoS 사파리 때문에 new Date() 그냥 못써서 이렇게 복잡한 결과가^^... 여러 시도 해봤으나 걍 새로고침하기로
        if (all) {
          openModal("modal2");
        } else {
          window.location.reload();
        }
      } else {
        alert("변경에 실패하였습니다. 다시 시도해주세요.");
      }
    });
  };

  // 사진 등록 클릭시 파일 선택창 open
  const fileInput = useRef();
  const openFile = () => {
    fileInput.current.click();
  };
  // 파일 선택시 동작
  const setFile = (e) => {
    let formData = new FormData();
    // var x_auth = localStorage.getItem("x_auth");
    formData.append("profileImg", e.target.files[0]);
    formData.append("x_auth", x_auth);

    let url = "/api/mypage/modify/uploadProfile";
    let config = { header: { "content-type": "multipart/form-data" } };

    axios.post(url, formData, config).then((res) => {
      if (res.data.result === "success") {
        setImg({
          profileName: res.data.profileName,
          profilePath: res.data.profilePath,
        });
        window.location.reload();
      } else {
        alert("프로필 등록 실패");
      }
    });
  };

  // 프로필 삭제
  let deleteProfile = () => {
    if (img.profilePath === "") return;

    let url = "/api/mypage/modify/deleteProfile";
    // var x_auth = localStorage.getItem("x_auth");
    let data = { profileName: img.profileName, x_auth: x_auth };
    axios.post(url, data).then((res) => {
      alert("삭제되었습니다.");
      window.location.reload();
      if (res.data.result === "success") {
        setImg({});
      } else {
        alert("사진 삭제에 실패하였습니다.");
      }
    });
  };

  // 비밀번호 변경하기
  const [changePw, setChangePw] = useState({
    presentPw: "",
    newPw: "",
    confirmPw: "",
  });

  const onChangeUserInfo = (e) => {
    // pw값 입력시 값 셋팅
    const value = e.target.value;
    setChangePw({
      ...changePw,
      [e.target.name]: value,
    });
  };

  // 닉네임 인풋 변경
  const memberHandler = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (e.target.name === "cellno") {
      if (regex.test(e.target.value)) {
        if (e.target.value.length === 10) {
          e.target.value = e.target.value.replace(
            /(\d{3})(\d{3})(\d{4})/,
            "$1-$2-$3"
          );
        }
        if (e.target.value.length === 13) {
          e.target.value = e.target.value
            .replace(/-/g, "")
            .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        }
        if (e.target.value.length > 13) {
          e.target.value = e.target.value.slice(0, -1);
        }
      } else {
        return;
      } // end of if (regex.test(value))
    } else if (e.target.name === "memname") {
      if (e.target.value.length > 10) {
        e.target.value = e.target.value.slice(0, -1);
      }
    }
    setMember({
      ...member,
      [e.target.name]: e.target.value.replace(" ", ""),
    });
  };

  // 닉네임 중복 여부 info
  const [warnNickname, setWarnNickname] = useState(true);
  const [memnameCheck, setMemnameCheck] = useState(true);
  // 닉네임 변경하기
  let changeMemname = () => {

    if(checkSpecial(member.memname)){
      alert("닉네임에 특수문자 또는 공백이 포함되어있습니다.");
      return;
    }

    if(checkOnlyNumber(member.memname)){
      alert("닉네임은 문자+숫자로 작성이 가능합니다.");
      return;
    }  

    if (member.memname.length < 3 || member.memname.length > 10) {
      setMemnameCheck(false);
      return;
    } else {
      setMemnameCheck(true);
    }
    setMember({
      ...member,
      memname: member.memname,
    });

    let url = "/api/mypage/modify/modifyMemname";
    // var x_auth = localStorage.getItem("x_auth");
    let data = { memname: strEncoding(member.memname), x_auth: x_auth };
    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        setMemname(member.memname);
        alert("닉네임이 변경되었습니다.");
        setWarnNickname(true);
      } else if (res.data.result === "false") {
        setWarnNickname(false);
      }
    });
  };

  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    // 유효성 검사 false일 때 focus
    inputRefs.current[index]?.focus();
  };

  const obj = {
    0: "",
    1: "비밀번호를 다시 확인하세요.",
    2: "비밀번호 형식이 올바르지 않습니다.",
    3: "이전과 다른 비밀번호를 입력하세요.",
    4: "비밀번호가 다릅니다. 다시 확인하세요.",
  };
  const [warning, setWarning] = useState({ id: 0, message: obj[0] });

  // 비밀번호 변경하기 클릭 시
  const submitPw = () => {
    if (!CheckPw(changePw["newPw"])) {
      setWarning({
        id: 2,
        message: obj[2],
      });
      handleFocus(1);
    } else if (changePw["newPw"] !== changePw["confirmPw"]) {
      setWarning({
        id: 4,
        message: obj[4],
      });
      handleFocus(2);
    } else {
      setWarning({
        id: 0,
        message: obj[0],
      });

      // 비밀번호 변경하기
      let url = "/api/mypage/modify/modifyPassword";
      // var x_auth = localStorage.getItem("x_auth");
      let data = {
        oldPassword: strEncoding(changePw.presentPw),
        newPassword: strEncoding(changePw.newPw),
        x_auth: x_auth,
      };

      axios.post(url, data).then((res) => {
        if (res.data.result === "success") {
          alert("비밀번호가 변경되었습니다.");
          setChangePw({
            ...changePw,
            presentPw: "",
            newPw: "",
            confirmPw: "",
          });
          closeModal("modal1");
        } else if (res.data.result === "wrong") {
          setWarning({
            id: 1,
            message: obj[1],
          });
        } else if (res.data.result === "same") {
          setWarning({
            id: 3,
            message: obj[3],
          });
        } else {
          alert("비밀번호 변경에 실패하였습니다.");
        }
      });
    }
  };

  //회원 탈퇴
  let deleteUserSubmit = () => {
    // let x_auth = localStorage.getItem("x_auth");
    let values = Object.values(reasonChk);
    values = values.filter((item) => item ?? item); //빈 문자열 제거
    if (values.length > 0) {
      let url = "/api/user/free/detail/user/deleteUser";
      let data = {
        unregdesc: values.join(","),
        x_auth: x_auth,
        memname: memname,
      };

      axios.post(url, data).then((res) => {
        if (res.data.result === "success") {
          alert("탈퇴되었습니다.");
          localStorage.clear();
          deleteCookie("x_auth");

          history.push("/");
        } else {
          alert("잠시후 다시 시도해주세요.");
        }
      });
    } else {
      alert("탈퇴하시는 이유를 하나 이상 선택해 주세요.");
    }
  };

  const encodeRef = useRef();

  let checkTel = () => {
    let cellno = member.cellno;
    cellno = cellno
      .replace(/-/g, "")
      .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");

    setMember({ ...member, cellno: cellno });

    if (cellno === "" || cellno.length < 13) {
      alert("휴대폰 번호를 입력해주세요.");
      return;
    }
    let url = "/api/join/checkTel";
    let data = {
      cellno: cellno,
    };
    axios.post(url, data).then((res) => {
      if (res.data.result === "success") {
        getEncData();
      } else {
        alert("이미 가입된 핸드폰 번호입니다.");
      }
    });
  };

  // 전화번호 수정
  // eslint-disable-next-line
  let getEncData = () => {
    let url = `/api/nice/checkplus_main`;
    url += `?sReturnUrl=${axios.defaults.baseURL}api/nice/checkplus_success`;
    url += `&sErrorUrl=${axios.defaults.baseURL}api/nice/checkplus_fail`;
    url += `&type=normal`;
    url += `&memid=${member.memid}`;
    url += `&token=token`;
    if (isMobile) {
      url += `&mobile=yes`;
    } else {
      url += `&mobile=no`;
    }
    url += `&page=mypage`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.sEncData) {
          encodeRef.current.value = res.data.sEncData;
          if (isMobile === true) {
            document.form_chk.action =
              "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
            document.form_chk.submit();
          } else {
            var uri = "";
            var popupName = "popupChk";
            var options =
              "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=n";

            var openDialog = function (uri, name, options, closeCallback) {
              var win = window.open(uri, name, options);

              document.form_chk.action =
                "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
              document.form_chk.target = "popupChk";
              document.form_chk.submit();

              var interval = window.setInterval(function () {
                try {
                  if (win == null || win.closed) {
                    window.clearInterval(interval);
                    closeCallback(win);
                  }
                } catch (e) {}
              }, 1000);
              return win;
            };
            openDialog(uri, popupName, options, function (win) {
              window.location.reload();
            });
          }
        } else {
          alert("본인인증 서버 요청에 실패하였습니다. 다시 시도해주세요");
        }
      })
      .catch((err) => console.log(err));
  }; //end of getEncData()

  return (
    <>
      <div className="root_container" id={isMobile ? "isMobile" : null}>
        {isMobile ? null : (
          <Header
            isLoginAuth={isLoginAuth}
            memname={memname}
            profilePath={profilePath}
          />
        )}
        <div className="main center_main mypage_main" id="mypageModify">
          {isMobile ? null : (
            <div className="mypage_header">
              <div className="depth_column">
                <Link to="/">
                  <img src={depthHome} alt="home" />
                </Link>
                <div className="board_name">
                  <span>〉</span>마이페이지
                </div>
              </div>
            </div>
          )}

          <div className="mypage_body">
            {isMobile ? null : <Sidebar />}
            <div className="content">
              <h2>
                <div style={{ paddingRight: 14 }} onClick={goBack}>
                  <img src={back} alt="back" className="isMobile_back" />
                </div>
                내 정보 수정
              </h2>

              <div className="profile_wrapper">
                <div className="profile_column">
                  <div className="profile">
                    <img
                      src={
                        img && img.profilePath ? img.profilePath : profileImg
                      }
                      alt="profile"
                    />
                    <div
                      className="file_name"
                      style={{
                        textAlign: "center",
                        width: 160,
                        wordBreak: "break-word",
                      }}
                    >
                      {/*img.profileName
                        ? img.profileName
                      : "등록된 프로필이 없습니다."*/}
                      {img && img.profileName
                        ? ""
                        : "등록된 프로필이 없습니다."}
                    </div>
                  </div>
                  <div className="profile_btn_column">
                    {img && img.profilePath ? (
                      <>
                        <div
                          className="btn"
                          onClick={() => {
                            deleteProfile();
                          }}
                        >
                          <img src={deleteIcon} alt="delete" />
                          삭제
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="btn add_picture" onClick={openFile}>
                          <img src={pictureImg} alt="pictureImg" />
                          사진등록
                        </div>
                      </>
                    )}
                    <input
                      type="file"
                      id="add_picture"
                      accept="image/*"
                      ref={fileInput}
                      onChange={setFile}
                    />
                  </div>
                </div>

                <form className="mypage modify_mypage">
                  <div className="column isMobile_column">
                    <div className="input_name">가입일</div>
                    <div className="input_info">
                      {member && member.memregdtime
                        ? changeDateFormat(member.memregdtime, 5, ".", ":")
                        : ""}
                    </div>
                  </div>

                  <div className="column warning_column">
                    <div className="input_name">닉네임</div>
                    <div className="input_column">
                      <input
                        type="text"
                        onChange={memberHandler}
                        name="memname"
                        value={member && member.memname ? member.memname : ""}
                      />
                      <div
                        className="check_btn"
                        onClick={() => {
                          changeMemname();
                        }}
                      >
                        변경하기
                      </div>
                    </div>
                  </div>
                  <div className="column " style={{ marginBottom: 0 }}>
                    <div className="input_name"></div>
                    <div className={memnameCheck ? "info" : "warning"}>
                      ※ 3~10자 사이로 입력하세요
                    </div>
                  </div>
                  <div className="column ">
                    <div className="input_name"></div>
                    {warnNickname ? (
                      ""
                    ) : (
                      <span className="warning">
                        중복된 닉네임입니다. 다른 닉네임을 입력하세요.
                      </span>
                    )}
                  </div>
                  <div
                    className="column isMobile_column"
                    style={{ marginBottom: 32 }}
                  >
                    <div className="input_name">이름</div>
                    <div className="input_info">
                      {member && member.memrealname ? member.memrealname : ""}
                    </div>
                  </div>

                  <div className="column isMobile_column">
                    <div className="input_name">아이디</div>
                    <div className="input_info">
                      {member && member.memid ? member.memid : ""}
                    </div>
                  </div>
                  <form
                    name="form_chk"
                    method="post"
                    style={{ display: "none" }}
                  >
                    <input type="hidden" name="m" value="checkplusService" />
                    <input ref={encodeRef} type="hidden" name="EncodeData" />
                  </form>
                  <div
                    className="column warning_column"
                    style={{ marginBottom: 10 }}
                  >
                    <div className="input_name">전화번호</div>
                    <div className="input_column">
                      <input
                        type="text"
                        name="cellno"
                        value={member && member.cellno ? member.cellno : ""}
                        onChange={memberHandler}
                      />
                      <div
                        className="check_btn"
                        onClick={() => {
                          checkTel();
                        }}
                      >
                        변경하기
                      </div>
                    </div>
                  </div>

                  <div className="column warning_column">
                    <div className="input_name"></div>
                    <div className="input_column certify_column">
                      {member && member.authdate ? (
                        <>
                          <div className="tel_certify_column">
                            <div className="tel_certify ok active">
                              인증완료
                            </div>
                            <div className="tel_certify no ">미인증</div>
                          </div>
                          <div className="info">
                            ※ 인증일시 :
                            {changeDateFormat(member.authdate, 5, ".", ":")}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="tel_certify_column">
                            <div className="tel_certify ok active">
                              인증완료
                            </div>
                            <div className="tel_certify no">미인증</div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="column">
                    {isMobile ? null : <div className="input_name"></div>}
                    <div className="info">
                      ※ 입력된 전화번호로 서비스 알림이 발송됩니다.
                    </div>
                  </div>

                  {member && member.memjointype === "이메일" ? (
                    <div className="column isMobile_column isMobile_column_pw">
                      <div className="input_name">비밀번호</div>
                      <div
                        className="check_btn change_password"
                        onClick={() => openModal("modal1")}
                      >
                        변경하기
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="column isMobile_column_ad">
                    <div className="input_name">광고성 정보 수신</div>
                    <div className="own_radio_column">
                      <div className="radio_wrap left">
                        <input
                          id="rd1"
                          type="radio"
                          name="radio1"
                          onChange={handleAgree}
                          checked={rds[0]}
                        />
                        <label htmlFor="rd1">
                          <span>동의</span>
                        </label>
                      </div>
                      <div className="radio_wrap">
                        <input
                          id="rd2"
                          type="radio"
                          name="radio1"
                          onChange={handleAgree}
                          checked={rds[1]}
                        />
                        <label htmlFor="rd2">
                          <span>비동의</span>
                        </label>
                      </div>
                      <div className="info">
                        ※ 변경일시 :
                        {member && member.advdate
                          ? changeDateFormat(member.advdate, 5, "-", ":")
                          : "변경내역이 존재하지 않습니다."}
                      </div>
                    </div>
                  </div>
                  <div className="column isMobile_column_chk">
                    <div className="input_name"></div>
                    <div className="checkbox_coloumn">
                      <div className="checkbox_wrap main">
                        <input
                          id="agreeReceive1"
                          type="checkbox"
                          name="agreeReceive1"
                          onChange={handleNotice}
                          checked={advCheckVal[0]}
                          disabled={rds[1]}
                        />
                        <label htmlFor="agreeReceive1">앱푸시</label>
                      </div>
                      <div className="checkbox_wrap main">
                        <input
                          id="agreeReceive2"
                          type="checkbox"
                          name="agreeReceive2"
                          onChange={handleNotice}
                          checked={advCheckVal[1]}
                          disabled={rds[1]}
                        />
                        <label htmlFor="agreeReceive2">이메일</label>
                      </div>
                      <div className="checkbox_wrap main">
                        <input
                          id="agreeReceive3"
                          type="checkbox"
                          name="agreeReceive3"
                          onChange={handleNotice}
                          checked={advCheckVal[2]}
                          disabled={rds[1]}
                        />
                        <label htmlFor="agreeReceive3">문자(SMS)</label>
                      </div>
                      <div className="checkbox_wrap main">
                        <input
                          id="agreeReceive4"
                          type="checkbox"
                          name="agreeReceive4"
                          onChange={handleNotice}
                          checked={advCheckVal[3]}
                          disabled={rds[1]}
                        />
                        <label htmlFor="agreeReceive4">알림톡</label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="mypage_modify_info">
                ㆍ광고성 정보 제공 및 이벤트 내용 발송 등의 목적으로 이메일,
                휴대폰 번호(SMS)를 수집하고 있습니다. <br />
                ㆍ수집되는 개인정보는 회원님의 광고성 정보 제공 동의 철회 또는
                회원 탈퇴 시 즉시 정보를 삭제하고 있습니다. <br />
                ㆍ광고성 정보 제공 동의 거부 시에도 집파인(집fine)에서 제공하는
                서비스를 이용할 수 있으며, 단 광고성 정보 및 이벤트 정보를
                받으실 수 없습니다. <br />
                ㆍ서비스의 중요한 변경 사항 또는 이용약관에 의거한 고지 의무에
                대한 정보는 위 수신 여부와 관계없이 발송됩니다.
              </div>

              <div
                className="next_btn withdrawal btn"
                onClick={() => openModal("modal3")}
              >
                회원탈퇴
              </div>
            </div>
          </div>
          <Modal open={modal["modal1"]} width={600} center={true}>
            <div className="modal_inner_ct wide_modal change_modal">
              <img
                src={close}
                alt="close"
                className="close btn"
                onClick={() => closeModal("modal1")}
              />
              <h2>비밀번호 변경하기</h2>

              <form>
                <div className="column warning_column">
                  <div className="input_name">현재 비밀번호</div>
                  <div className="input_column">
                    <input
                      type="password"
                      placeholder="비밀번호를 입력하세요."
                      onChange={onChangeUserInfo}
                      name="presentPw"
                      ref={(elem) => (inputRefs.current[0] = elem)}
                      value={changePw.presentPw ? changePw.presentPw : ""}
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="input_name"></div>
                  <div className="warning">
                    {warning["id"] === 1 ? warning["message"] : null}
                  </div>
                </div>
                <div className="column">
                  <div className="input_name">새로운 비밀번호</div>
                  <div className="input_column">
                    <input
                      type="password"
                      placeholder="변경할 비밀번호를 입력하세요."
                      onChange={onChangeUserInfo}
                      name="newPw"
                      ref={(elem) => (inputRefs.current[1] = elem)}
                      value={changePw.newPw ? changePw.newPw : ""}
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="input_name"></div>
                  <div className="warning">
                    {warning["id"] === 2 || warning["id"] === 3
                      ? warning["message"]
                      : null}
                  </div>
                </div>
                <div className="column warning_column">
                  <div className="input_name">새로운 비밀번호 확인</div>
                  <div className="input_column">
                    <input
                      type="password"
                      placeholder="비밀번호를 다시 한번 입력하세요."
                      onChange={onChangeUserInfo}
                      name="confirmPw"
                      ref={(elem) => (inputRefs.current[2] = elem)}
                      value={changePw.confirmPw ? changePw.confirmPw : ""}
                    />
                  </div>
                </div>
                <div className="column">
                  {isMobile ? null : <div className="input_name"></div>}
                  <div className="warning" style={{ height: "auto" }}>
                    {warning["id"] === 4 ? warning["message"] : null}
                  </div>
                </div>
                <div className="column">
                  {isMobile ? null : <div className="input_name"></div>}

                  <div className="info">
                    ※ 8자리 이상, 영문, 숫자, 특수문자을(를) 사용하세요.
                  </div>
                </div>
              </form>
              <div className="save_btn_column">
                <div
                  className="btn blue_btn close"
                  onClick={() => closeModal("modal1")}
                >
                  취소
                </div>
                <div className="blue_btn btn" onClick={submitPw}>
                  변경하기
                </div>
              </div>
            </div>
          </Modal>

          <Modal open={modal["modal2"]} width={400}>
            <div className="modal_inner_ct modal_400 agree_ad">
              <h2>광고성 정보 수신</h2>
              <div className="info_column">
                <div className="info">{agreeMessage}</div>
                <div className="date">일시 : {getTodayDateNTime("ko")}</div>
              </div>
              <div className="save_btn_column">
                <div
                  className="blue_btn btn"
                  onClick={() => {
                    closeModal("modal2");
                    window.location.reload();
                  }}
                >
                  확인
                </div>
              </div>
            </div>
          </Modal>

          <Modal open={modal["modal3"]} width={600} center={true}>
            <div className="modal_inner_ct wide_modal withdrawal_modal">
              <img
                src={close}
                alt="close"
                className="close btn"
                onClick={() => closeModal("modal3")}
              />
              <h2>회원 탈퇴</h2>

              <form>
                <div className="question">
                  <h2>정말 탈퇴하시겠습니까?</h2>
                  <div className="question_info">
                    탈퇴하시는 이유가 무엇인가요? <br />
                    해당 항목을 모두 선택해주시면 서비스 개선에 더욱
                    노력하겠습니다.
                  </div>

                  <div className="checkbox_coloumn">
                    <div className="checkbox_wrap main">
                      <input
                        id="withdrawal1"
                        type="checkbox"
                        name="reasonChk1"
                        value="원하는 서비스가 없습니다"
                        onChange={chkReason}
                      />
                      <label htmlFor="withdrawal1">
                        원하는 서비스가 없습니다.
                      </label>
                    </div>
                    <div className="checkbox_wrap main">
                      <input
                        id="withdrawal2"
                        type="checkbox"
                        name="reasonChk2"
                        value="서비스가 필요 없습니다"
                        onChange={chkReason}
                      />
                      <label htmlFor="withdrawal2">
                        서비스가 필요 없습니다.
                      </label>
                    </div>
                    <div className="checkbox_wrap main">
                      <input
                        id="withdrawal3"
                        type="checkbox"
                        name="reasonChk3"
                        value="개인정보 보안이 걱정됩니다"
                        onChange={chkReason}
                      />
                      <label htmlFor="withdrawal3">
                        개인정보 보안이 걱정됩니다.
                      </label>
                    </div>
                    <div className="checkbox_wrap main">
                      <input
                        id="withdrawal4"
                        type="checkbox"
                        name="reasonChk4"
                        value="알림이 오지 않습니다"
                        onChange={chkReason}
                      />
                      <label htmlFor="withdrawal4">알림이 오지 않습니다.</label>
                    </div>
                    <div className="checkbox_wrap main">
                      <input
                        id="withdrawal5"
                        type="checkbox"
                        onChange={checkEtc}
                      />
                      <label htmlFor="withdrawal5">
                        기타. 다른 이유가 있습니다
                      </label>
                    </div>
                    {reason ? (
                      <textarea
                        placeholder="탈퇴사유를 입력해주세요."
                        className="withdrawal_reason"
                        name="reasonChk5"
                        onChange={chkReason}
                      ></textarea>
                    ) : null}
                  </div>

                  <div className="question_info bottom">
                    탈퇴 시, 회원 정보와 등록하신 모든 정보가 삭제됩니다. <br />
                    탈퇴 신청 후 삭제까지는 1~2 영업일이 소요될 수 있습니다.
                    <br />
                    <strong>탈퇴할까요?</strong>
                  </div>
                </div>
              </form>
              <div className="withdrawal_btn_column">
                <div className="red_btn btn" onClick={doubleModal}>
                  탈퇴하기
                </div>
                <div
                  className="btn blue_btn close"
                  onClick={() => closeModal("modal3")}
                >
                  취소
                </div>
              </div>
            </div>
          </Modal>
          <Modal open={modal["modal4"]} width={400}>
            <div className="modal_inner_ct modal_400 withdrawal_modal">
              <h2 className="modal_title">정말 회원탈퇴 하시겠습니까?</h2>

              <div className="withdrawal_btn_column really">
                <div
                  className="btn blue_btn close"
                  onClick={() => closeModal("modal4")}
                >
                  취소
                </div>
                <div
                  className="red_btn btn"
                  onClick={() => {
                    deleteUserSubmit();
                  }}
                >
                  탈퇴하기
                </div>
              </div>
            </div>
          </Modal>
        </div>
        {isMobile ? <MobileBottomNav /> : <Footer />}
      </div>
    </>
  );
}

export default MypageModify;
