import React, { useCallback, useLayoutEffect, useState } from "react";
import "../admin.css";
import tab from "../../images/admin/tab.png";
import * as XLSX from 'xlsx';
// import PaginationNum from "../../components/PaginationNumTest";
import PaginationNum from "../../components/PaginationNum";
import Header from "../Header/Header";
import { changeDateFormat, searchCashClear, searchDataSet, getToday } from "../../Common/lib";
import { BrowserRouter, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { 
  addressAll, 
  addressSearch, 
  addressCallAPI, 
  addressCallOwnerAPI 
} from "../../_actions/admin_board_action";

function Address({ location, history, match }) {
  let searchParam = (key) => {
    return new URLSearchParams(location.search).get(key);
  };

  let qsMemname = searchParam("memname");
  let pagen = match.params.pagen;

  const dispatch = useDispatch();
  // 달력 시작일 셋팅
  // eslint-disable-next-line
  const [searchDate, setSearchDate] = useState({
    start: "",
    end: "",
  });

  const [boardListCnt, setBoardListCnt] = useState();
  const [boardSearchListCnt, setBoardSearchListCnt] = useState(0);
  const [boardList, setBoardList] = useState([]);

  const [nowPage, setNowPage] = useState();
  const [pageOfItems, setPageOfItems] = useState([]);
  // 페이징 처리
  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };
  const getPageNum = (pageNum) => {
    //페이지 번호저장
    setNowPage(pageNum);
    history.replace(`/rf_zib_admin/address/${pageNum}`);
  };

  const [disapproval, setDisapproval] = useState(0); // 미승인 카운트
  const [approval, setApproval] = useState(0); // 승인완료 카운트
  const [hold, setHold] = useState(0); // 보류 카운트
  let searchInit = {
    startDate: searchDate.start,
    endDate: searchDate.end,
    addrower: "전체",
    verstdate: "전체",
    addrtype: "전체",
    memstatus: "전체",
    keyword: "",
    addralarmstart: "전체",
  };

  // 체크된 아이템을 담을 배열
  const [checkItems, setCheckItems] = useState("");
  // 체크박스 리스트
  const checkBoxData = [
    {id: 0, title: '미승인', type: 'addrtype', value:'미승인', area:'0'},
    {id: 1, title: '미처리', type: 'addrtype2', value:'null', area:'0'},
    {id: 2, title: '상세입력주소 불확실', type: 'addrtype2', value:'1', area:'0'},
    {id: 3, title: '관계인 확인불가', type: 'addrtype2', value:'2', area:'0'},
    {id: 4, title: '건물등기 없음', type: 'addrtype2', value:'3', area:'0'},
    {id: 5, title: '기타', type: 'addrtype2', value:'4', area:'0'},
    {id: 6, title: '승인완료', type: 'addrtype', value:'승인완료', area:'1'},
    {id: 7, title: '승인', type: 'addrtype2', value:'1', area:'1'},
    {id: 8, title: '관계인 불일치', type: 'addrtype2', value:'2', area:'1'},
    {id: 9, title: '관계인 불일치 및 소유권 이전', type: 'addrtype2', value:'3', area:'1'},
    {id: 10, title: '기타', type: 'addrtype2', value:'4', area:'1'},
    {id: 11, title: '보류', type: 'addrtype', value:'보류', area:'2'},
  ];

  const resultExcelDownload = useCallback((execlData) => {
    const workbook = XLSX.utils.book_new();
        let view = [];
    view[0] = ['등록일시',	'회원이름',	'회원닉네임',	'부동산닉네임',	'소유형태',	'경매정보',	'관계인(소유주)',	'부동산고유번호',	'최종처리일',	'승인결과',	'결과사유',	'회원상태']
    
    execlData.map((item,index) => {
      view[index+1] = [item.registerdate, item.memrealname, item.memname, item.addrnickname, item.addrower, item.verstdate, item.owner, item.addrucode, item.addrtypedate, item.addrtype, '', item.memstatus]
    })

    const sheetName = XLSX.utils.aoa_to_sheet(view)

    console.log(execlData);
    console.log(view);

    XLSX.utils.book_append_sheet(workbook, sheetName, 'Address1');
    XLSX.writeFile(workbook, 'test1.xlsx');
  },[])

  // 해당 아이템에 부모 체크박스값 추출
  const getAreaBox = (item) => {
    let getdate;

    checkBoxData.forEach((el) => {
      if(el.area === item.area && el.type === "addrtype"){
        getdate = el.id;
      }
    })
    return getdate;
  }

  // 자식 체크박스 검사
  const searchChildrenBox = (item) => {
    let ischeck = false;

    // checkItems안에 데이터가 있으면
    if(checkItems){
      checkItems.forEach((el) => {
        if(checkBoxData[el].area === item.area && checkBoxData[el].type === "addrtype2" && checkBoxData[el].id !== item.id){
          ischeck = true;
        }
      })
    }
    return ischeck;
  }

  // 체크박스 단일 선택
  const handleSingleCheck = (checked, id) => {
    let checkData = checkBoxData[id]; // 체크한 id값 데이터 추출
    let parent = getAreaBox(checkData); // 부모 체크박스 id 추출
    let idArray = [...checkItems];

    if (checked) {
      // 단일 선택 시 체크된 아이템을 배열에 추가
      idArray.push(id);
      // 배열에 부모 체크박스가 있는지 확인
      let isCheck = idArray.find(c => c === parent)
      // 부모 체크박스가 없으면 추가
      if(typeof isCheck === "undefined" ){
        idArray.push(parent);
      }
      // 체크박스 세팅
      setCheckItems(idArray);
    } else {
      // 자식 체크박스 확인
      let isCheck = searchChildrenBox(checkData);

      // 클릭한 체크박스 제외
      idArray = idArray.filter(function(data) {
        return data !== id;
      });

      // 자식 체크박스가 더이상 없으면 부모체크박스 제외
      if(isCheck === false){
        idArray = idArray.filter(function(data) {
          return data !== parent;
        });
      }

       // 체크박스 세팅
      setCheckItems(idArray);
    }
  };

  // 체크박스 영역 선택
  const handleAreaCheck = (checked, area) => {
    if (checked) {
      let idArray = [...checkItems];
      // 체크박스 리스트에서 조건에 맞는 아이템 추출
      checkBoxData.forEach((el) => {
        if(el.area === area){
          idArray.push(el.id)
        }
      });
      // type에 관련된 아이템을 배열에 추가
      setCheckItems(idArray);

    } else {
      let idArray = [...checkItems];
      checkBoxData.forEach((item, idx) => {
        if(item.area === area){
          idArray = idArray.filter(function(data) {
            return data !== item.id;
          });
        }
      });
      // type에 관련된 아이템을 배열에 추가
      setCheckItems(idArray);
    }
  };

  // 체크박스 전체 선택
  const handleAllCheck = (checked) => {
    if(checked) {
      // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
      const idArray = [];
      checkBoxData.forEach((el) => idArray.push(el.id));
      setCheckItems(idArray);
    }
    else {
      // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
      setCheckItems([]);
    }
  }

  const [searchData, setSearchData] = useState(searchInit);

  const searchHandler = (e) => {
    let el = e.target;
    setSearchData({ ...searchData, [el.name]: el.value });
  };
  // 검색창 밑 등록상태 별 개수 세기
  const countAddrStatus = (result) => {
    let cnt1 = 0;
    let cnt2 = 0;
    let cnt3 = 0;

    result !== "false" &&
      result.forEach((item) => {
        switch (item.addrtype) {
          case "미승인":
            cnt1++;
            break;
          case "승인완료":
            cnt2++;
            break;
          case "보류":
            cnt3++;
            break;

          default:
            break;
        }
      });

    setDisapproval(cnt1);
    setApproval(cnt2);
    setHold(cnt3);
  };

   // qsMemname는 사용자관리에서 회원 주소등록 건 검색하는 기능이 있어서...
  useLayoutEffect(() => {

    let body = {
      startDate: searchData.startDate,
      endDate: searchData.endDate,
      addrower: searchData.addrower,            // 소유형태
      verstdate: searchData.verstdate,          // 경매정보
      addrtype: searchData.addrtype,            // 승인,미승인,승인완료
      memstatus: searchData.memstatus,          // 정상,휴면,정지,탈퇴
      keyword: qsMemname ? qsMemname : searchData.memstatus,
      addralarmstart: searchData.addralarmstart,
      checkBoxState:checkItems,                 // 체크박스 상태
    };

    //체크박스 전체선택
    handleAllCheck(true);

    let finalData = searchDataSet(body);
    if(localStorage.getItem("searchInput")){
      qsMemname = true;
    }

    dispatch(qsMemname ? addressSearch(finalData) : addressAll()).then((res) => {
      if (res.payload.result === "success") {
        let result = res.payload.address;
        if (qsMemname) {
          if (result === "false") setBoardSearchListCnt(0);
          else setBoardSearchListCnt(result.length);
        } else {
          setBoardListCnt(result.length);
        }

        setBoardList(result);
        countAddrStatus(result);

        // State 변경
        if(finalData.checkBoxState !== ""){
          setCheckItems(finalData.checkBoxState);
        }
        setSearchData({...searchData, 
          startDate: finalData.startDate, 
          endDate: finalData.endDate, 
          addrower: finalData.addrower, 
          verstdate: finalData.verstdate,
          memstatus: finalData.memstatus

        });


      } else {
        alert("잠시 후 다시 시도해주세요");
      }
    });
    // eslint-disable-next-line
  }, []);

  // 승인타입 쿼리작성
  const addrtypeSQL = (item) => {
    let type1 = "";
    let type1Data = "";
    let type2 = "";
    let type2Data = "";
    let type3 = "";
    let isnull = false
    let nullSQL = "";
    
    let finalSQL = "";

    if(item){
      item.forEach((el, index) => {
        if(checkBoxData[el].area === "0" ){
          if(checkBoxData[el].type === "addrtype"){
            type1 = "( addrtype = '미승인'"
          }
          if(checkBoxData[el].type === "addrtype2"){
            if(checkBoxData[el].value === 'null'){
              isnull = true
            }
            type1Data += checkBoxData[el].value + "," ;
          }
        }

        if(checkBoxData[el].area === "1" ){
          if(checkBoxData[el].type === "addrtype"){
            type2 = "( addrtype = '승인완료'"
          }
          if(checkBoxData[el].type === "addrtype2"){
            if(checkBoxData[el].value === '1'){
              isnull = true
            }
            type2Data += checkBoxData[el].value + "," ;
          }
        } 

        if(checkBoxData[el].area === "2" ){
          if(checkBoxData[el].type === "addrtype"){
            type3 = "( addrtype = '보류' )"
          }
        } 


      });

      //첫번째 쿼리
      if(type1 !== "" && type1Data === ""){
        type1 += ")" 
      }else if(type1 !== "" && type1Data !== ""){
        if(isnull){nullSQL = "OR (addrtype = '미승인' AND addrtype2 IS NULL) "}
        type1 += " AND addrtype2 IN ("+ type1Data.substring(0,type1Data.length - 1) +") " + nullSQL + " ) ";
      }

      //두번째 쿼리
      if(type2 !== "" && type2Data === ""){
        type2 += ")" 
      }else if(type2 !== "" && type2Data !== ""){
        if(isnull){nullSQL = "OR (addrtype = '승인완료' AND addrtype2 IS NULL) "}
        type2 += " AND addrtype2 IN ("+ type2Data.substring(0,type2Data.length - 1) +") " + nullSQL + " ) ";
      }

    }
   
    // 쿼리 병합
    finalSQL = type1;
    if(finalSQL.length > 0 && type2.length > 0){
      finalSQL += " OR " + type2;
    }else{
      finalSQL += type2;
    }
    if(finalSQL.length > 0 && type3.length > 0){
      finalSQL += " OR " + type3;
    }else{
      finalSQL += type3;
    }
    if(finalSQL.length > 0){
      finalSQL = "("+ finalSQL + ")";
    }

    return finalSQL;
  }


  // 검색하기
  const handleSearch = () => {
    let addrtype = addrtypeSQL(checkItems);

    history.replace(`/rf_zib_admin/address/1`);
    let body = {
      startDate: searchData.startDate,
      endDate: searchData.endDate,
      addrower: searchData.addrower,          // 소유형태
      verstdate: searchData.verstdate,        // 경매정보
      addrtype: addrtype,                     // 승인,미승인,승인완료
      memstatus: searchData.memstatus,        // 승인,미승인,승인완료
      keyword: searchData.keyword,            // 검색어
      addralarmstart: searchData.addralarmstart,
      checkBoxState:checkItems,               // 체크박스 상태
    };

    // 검색조건을 로컬스토리지에 저장
    localStorage.setItem("searchInput", JSON.stringify(body));

    dispatch(addressSearch(body)).then((res) => {
      if (res.payload.result) {
        const result = res.payload.address;
        setBoardList(result);
        if (result === "false") setBoardSearchListCnt(0);
        else setBoardSearchListCnt(result.length);

        countAddrStatus(result);
      } else {
        alert("잠시 후 다시 시도해주세요");
      }
    });
  };

  // 검색 후 enter 눌렀을 때
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // 코멘트 내용 변경
  const commentChg = (item, addrtype) => {
    let comment;

    if(item === null && addrtype === "미승인"){
      comment = "미처리";
    }else if(item === null && addrtype === "승인완료"){
      comment = "승인";
    }else if(item === "관계인(소유주) 불일치 및 소유권 이전"){
      comment = "관계인 불일치 및 소유권 이전";
    }else {
      comment = item;
    }

    return comment
  }

  // PTS 호출 상태
  const ptsResultState = (result,date) => {
    let item = "";
    
    if(date === undefined || date === null || date === ""){ date = ""; }

    switch (result){
      case 'N': item = "미진행";
      break;
      case 'W': item = <span style={{color:'#1876bb'}}>진행중</span>;
      break;
      case 'Y': item = <span style={{lineHeight:"1.3"}}>처리완료<br/>{date}</span>;
      break;
      case 'F': item = <span style={{color:'#ff0000', lineHeight:"1.3"}}>실패<br/>{date}</span>;
      break;
    }
    return item;
  }

  const pageOfItem =
    pageOfItems.length > 0 &&
    pageOfItems.map((item, index) => {
      let ownerList = [];
      let addrucodeList = [];
      let pageSize = 10;

      if (item.owner) ownerList = item.owner.split(",");
      if (item.addrucode) addrucodeList = item.addrucode.split(",");

      return (
        <tr key={index}>
          <td>{boardList.length - ((nowPage - 1) * pageSize) - index }</td>
          {/* <td>{item.idx}</td> */}
          <td>{changeDateFormat(item.registerdate, 5, ".", ":")}</td>
          <td>{item.memrealname}</td>
          <td>{item.memname}</td>
          <td>
            <Link to={`/rf_zib_admin/address/detail/${item.idx}/${pagen}`}>
              <span className="btn">{item.addrnickname}</span>
            </Link>
          </td>
          <td>{item.addrower}</td>
          <td>
            {item.verstdate
              ? changeDateFormat(item.verstdate, 3, ".", "")
              : "-"}
          </td>
          <td>
            {item.owner && ownerList[0]}
            {item.owner && ownerList.length - 1 > 0
              ? " 외 " + (ownerList.length - 1) + "명"
              : ""}
          </td>
          <td>
            {item.addrucode && addrucodeList[0]}
            {item.addrucode && addrucodeList.length > 1
              ? " 외 " + (addrucodeList.length - 1)
              : ""}
          </td>
          <td>
            {item.addrtypedate
              ? changeDateFormat(item.addrtypedate, 5, ".", ":")
              : "-"}
          </td>
          <td className={item.addrtype === "미승인" ? "stop" : null}>
            {item.addrtype}
          </td>
          <td>{commentChg(item.etcomment,item.addrtype)}</td>
          <td>{item.memstatus}</td>
          <td>{ptsResultState(item.ownerapi,changeDateFormat(item.resultdate, 5, ".", ":"))}</td>
        </tr>
      );
    });

  return (
    <>
      <Header />
      <div className="admin_container" id="address">
        <ul className="side_container">
          <Link to="/rf_zib_admin/address/1">
            <li
              className="side_tab active"
              onClick={() => {
                window.location.pathname = "/rf_zib_admin/address/1";
              }}
            >
              등록주소
              <img src={tab} alt="tab" />
            </li>
          </Link>
        </ul>
        <div className="main_container">
          <div>
            <h2 className="main_container_name">
              <BrowserRouter forceRefresh={true}>
                <Link to="/rf_zib_admin/address/1">등록주소</Link>
              </BrowserRouter>
              <span className="total_column">
                전체 <span>{boardListCnt}</span>
              </span>
            </h2>
            <div className="user_search_column">
              <div className="first_column">
                <div className="search_regdate_column">
                  <span className="input_title">등록일</span>
                  <input
                    type="date"
                    value={searchData.startDate}
                    name="startDate"
                    onChange={searchHandler}
                  />
                  <span className="range_text">~</span>
                  <input
                    type="date"
                    value={searchData.endDate}
                    name="endDate"
                    onChange={searchHandler}
                  />
                </div>
                <div className="search_keyword_column">
                  <span className="input_title">검색</span>
                  <input
                    type="text"
                    placeholder="회원이름, 회원닉네임,부동산닉네임,관계인, 고유번호"
                    name="keyword"
                    value={searchData.keyword}
                    onChange={searchHandler}
                    onKeyPress={onKeyPress}
                  />
                </div>
              </div>
              <div className="second_column top">
                <div className="register_address" style={{ minWidth: "400px" }}>
                  <div class="search_column">
                    <span className="input_title">소유형태</span>

                    <input
                      type="radio"
                      id="radio1"
                      name="addrower"
                      value="전체"
                      onChange={searchHandler}
                      defaultChecked={searchData.addrower === "전체" ? true : false}
                    />
                    <label htmlFor="radio1">전체</label>
                    <input
                      type="radio"
                      id="radio2"
                      name="addrower"
                      value="자가"
                      onChange={searchHandler}
                      defaultChecked={searchData.addrower === "자가" ? true : false}
                    />
                    <label htmlFor="radio2">자가</label>
                    <input
                      type="radio"
                      id="radio3"
                      name="addrower"
                      value="임차"
                      onChange={searchHandler}
                      defaultChecked={searchData.addrower === "임차" ? true : false}
                    />
                    <label htmlFor="radio3">임차</label>
                  </div>

                  <div class="search_column">
                    <span className="input_title">회원상태</span>

                    <input
                      type="radio"
                      id="radio14"
                      name="memstatus"
                      value="전체"
                      onChange={searchHandler}
                      defaultChecked={searchData.memstatus === "전체" ? true : false}
                    />
                    <label htmlFor="radio14">전체</label>
                    <input
                      type="radio"
                      id="radio15"
                      name="memstatus"
                      value="정상"
                      onChange={searchHandler}
                      defaultChecked={searchData.memstatus === "정상" ? true : false}
                    />
                    <label htmlFor="radio15">정상</label>
                    <input
                      type="radio"
                      id="radio16"
                      name="memstatus"
                      value="휴면"
                      onChange={searchHandler}
                      defaultChecked={searchData.memstatus === "휴면" ? true : false}
                    />
                    <label htmlFor="radio16">휴면</label>
                    <input
                      type="radio"
                      id="radio17"
                      name="memstatus"
                      value="정지"
                      onChange={searchHandler}
                      defaultChecked={searchData.memstatus === "정지" ? true : false}
                    />
                    <label htmlFor="radio17">정지</label>
                  </div>
                  <div class="search_column" >
                    <span className="input_title">경매정보</span>

                    <input
                      type="radio"
                      id="radio4"
                      name="verstdate"
                      value="전체"
                      onChange={searchHandler}
                      defaultChecked={searchData.verstdate === "전체" ? true : false}
                    />
                    <label htmlFor="radio4">전체</label>
                    <input
                      type="radio"
                      id="radio5"
                      name="verstdate"
                      value="등록"
                      onChange={searchHandler}
                      checked={searchData.verstdate === "등록" ? true : false}
                    />
                    <label htmlFor="radio5">등록</label>
                    <input
                      type="radio"
                      id="radio6"
                      name="verstdate"
                      value="미등록"
                      onChange={searchHandler}
                      checked={searchData.verstdate === "미등록" ? true : false}
                    />
                    <label htmlFor="radio6">미등록</label>
                  </div>
                </div>
                <div className="bar" style={{height:"80px"}}></div>
                <div className="address_checkbox">
                  <span className="input_title" style={{marginLeft:10}}>등록상태</span>
                  <input 
                    type='checkbox' 
                    id="checkAll"
                    name='select-all'
                    value="전체"
                    onChange={(e) => handleAllCheck(e.target.checked)}
                    // 데이터 개수와 체크된 아이템의 개수가 다를 경우 선택 해제 (하나라도 해제 시 선택 해제)
                    checked={checkItems.length === checkBoxData.length ? true : false} 
                  />
                  <label style={{marginRight:0}} htmlFor="checkAll">전체</label>
                </div>
                <div className="bar" style={{height:"80px"}}></div>
                <div className="address_checkbox" style={{minWidth:"108px"}}>
                  {checkBoxData.map((data, key) => {
                    if(data.type === "addrtype"){
                      return ( <div style={{height:"30px"}}>
                              <input type='checkbox' name={`${data.type}`} id={`select-${data.id}`} value={data.value}
                                onChange={(e) => handleAreaCheck(e.target.checked, data.area)}
                                // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                checked={checkItems.includes(data.id) ? true : false} />
                              <label htmlFor={`select-${data.id}`}>{data.title}</label>
                            </div>)
                    }
                  }
                  )}
                </div>
                <div style={{display:"flex", flexDirection:"column", height:80, minWidth:560}}>
                    {/** 체크박스 세부1 */}
                    <div style={{display:"flex"}}>
                      {checkBoxData.map((data, key) => {
                          if(data.area === "0" && data.type === "addrtype2"){
                            return <div style={{height:"30px"}}>
                                    <input type='checkbox' name={`${data.type}`} id={`${data.type}-${data.id}`} value={data.value}
                                      onChange={(e) => handleSingleCheck(e.target.checked, data.id)}
                                      // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                      checked={checkItems.includes(data.id) ? true : false} />
                                    <label htmlFor={`${data.type}-${data.id}`} style={{marginRight:20, fontSize:14}}>{data.title}</label>
                                  </div>
                          }
                        }
                        )}
                    </div>
                    {/** 체크박스 세부2 */}
                    <div style={{display:"flex"}}>
                      {checkBoxData.map((data, key) => {
                          if(data.area === "1" && data.type === "addrtype2"){
                            return <div style={{height:"30px"}}>
                                    <input type='checkbox' name={`${data.type}`} id={`${data.type}-${data.id}`} value={data.value}
                                      onChange={(e) => handleSingleCheck(e.target.checked, data.id)}
                                      // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                      checked={checkItems.includes(data.id) ? true : false} />
                                    <label htmlFor={`${data.type}-${data.id}`} style={{marginRight:20, fontSize:14}}>{data.title}</label>
                                  </div>
                          }
                        }
                        )}
                    </div>
                </div>
              </div>
              
              <div className="second_column">
                {/* 얘 없으니까 버튼이 너무 붙어서 어쩔 수 없이... 원래 회원 상태 라디오버튼 자리 */}
              </div>

              <div className="admin_btn_column">
                <BrowserRouter forceRefresh={true}>
                  <Link to="/rf_zib_admin/address/1">
                    <div className="admin_white_btn btn left" onClick={()=>{searchCashClear();}}>기본설정</div>
                  </Link>
                </BrowserRouter>
                <div
                  className="admin_blue_btn btn"
                  onClick={() => {
                    handleSearch();
                  }}
                >
                  검색
                </div>
              </div>
            </div>

            <div className="list_counter_column">
              <div className="list_counter all">
                <h3>전체</h3>
                <span>
                  <strong>{disapproval + approval + hold}</strong>건
                </span>
              </div>
              <div className="list_counter">
                <h3>미승인</h3>
                <span>
                  <strong>{disapproval}</strong>건
                </span>
              </div>
              <div className="list_counter">
                <h3>승인완료</h3>
                <span>
                  <strong>{approval}</strong>건
                </span>
              </div>
              <div className="list_counter">
                <h3>보류</h3>
                <span>
                  <strong>{hold}</strong>건
                </span>
              </div>
            </div>
            <div className="user_list_column">
              <div className="title_column">
                  <h3>목록</h3>
                  {boardSearchListCnt ? (
                    <div className="search_result">
                      검색결과 <span> {boardSearchListCnt}</span>
                    </div>
                  ) : (
                    <div className="search_result"></div>
                  )}
              </div>

              <table>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>등록일시</th>
                    <th>회원이름</th>
                    <th>회원닉네임</th>
                    <th>부동산닉네임</th>
                    <th>소유형태</th>
                    <th>경매정보</th>
                    <th>관계인(소유주)</th>
                    <th>부동산 고유번호</th>
                    <th>최종처리일</th>
                    <th>승인결과</th>
                    <th>결과사유</th>
                    <th>회원상태</th>
                    <th>PTS 호출</th>
                  </tr>
                </thead>
                <tbody>
                  {pageOfItem.length > 0 ? (
                    pageOfItem
                  ) : (
                    <tr>
                      <td colSpan={13}>등록 정보가 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <PaginationNum
                items={boardList}
                onChangePage={onChangePage}
                getPageNum={getPageNum}
                initialPage={pagen++}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Address;
