import React, { useLayoutEffect } from "react";
import "./Header.css";
import logo from "../../images/logo.svg";
import profile from "../../images/profile.png";
import notice from "../../images/notice.png";
import setting from "../../images/setting.png";
import { BrowserRouter, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getAlarmCount } from "../../_actions/user_action";
import { getCookie } from "../../Common/lib";

function Header({ isLoginAuth, memname, profilePath }) {
  let dispatch = useDispatch();

  let alarms = useSelector(
    (state) => state.adminInfo.alarmCount
    // + state.adminInfo.counselingCount + state.adminInfo.inquiryCount
  );
  let login = useSelector((state) => state.adminInfo.adminInfo);
  const getProfile = profilePath;

  useLayoutEffect(() => {
    if (isLoginAuth) {
      //로그인일때만 알림개수 가져오기
      getAlarmNonRead();
    }
    // eslint-disable-next-line
  }, [login]);

  // 읽지 않은 알림 개수 가져오기
  let getAlarmNonRead = () => {
    let url = "/api/alarm/count";
    const x_auth = getCookie("x_auth");
    // var x_auth = localStorage.getItem("x_auth");
    let body = {
      x_auth: x_auth,
    };
    axios.post(url, body).then((res) => {
      dispatch(getAlarmCount("alarm", res.data.alarmCount));
    });
  };

  return (
    <>
      <header id="header">
        <div className="container">
          <BrowserRouter forceRefresh={true}>
            <Link to="/">
              <img src={logo} alt="logo" className="main_logo" />
            </Link>
          </BrowserRouter>
          <div className="wraaper">
            {isLoginAuth ? (
              <>
                <div className="hello_user">안녕하세요. {memname} 님</div>
                <BrowserRouter forceRefresh={true}>
                  <Link to={"/mypage"}>
                    <img
                      style={{ width: 36, height: 36, borderRadius: "50%" }}
                      src={getProfile ? getProfile : profile}
                      alt="profile"
                      className="isMobile_profile_icon"
                    />
                  </Link>
                </BrowserRouter>
                <div className="notice_column">
                  <BrowserRouter forceRefresh={true}>
                    <Link to="/mypage/notice">
                      <img
                        src={notice}
                        alt="notice"
                        className="isMobile_notice_icon"
                      />
                      <div className="notice_count">{alarms}</div>
                    </Link>
                  </BrowserRouter>
                </div>
                <BrowserRouter forceRefresh={true}>
                  <Link to="/mypage/modify">
                    <img
                      src={setting}
                      alt="setting"
                      className="isMobile_set_icon"
                    />
                  </Link>
                </BrowserRouter>
              </>
            ) : (
              <>
                <BrowserRouter forceRefresh={true}>
                  <Link to="/login">
                    <div className="login_btn">로그인</div>
                  </Link>
                </BrowserRouter>

                <BrowserRouter forceRefresh={true}>
                  <Link to="/join">
                    <div className="join_btn">회원가입</div>
                  </Link>
                </BrowserRouter>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
