import "./Footer.css";
import footerLogo from "../../images/footer_logo.png";
import Modal from "../../components/Modal";
import close from "../../images/close.png";
import axios from "axios";
import { useState, useLayoutEffect } from "react";
import { BrowserRouter, Link } from "react-router-dom";

function Footer({ children }) {
  // 모달 control
  const [modal, setModal] = useState({
    modal1: false,
    modal2: false,
  });

  const openModal = (name) => {
    setModal({ ...modal, [name]: true });
  };

  const closeModal = (name) => {
    setModal({ ...modal, [name]: false });
  };

  // 이용약관
  const [provisionContent1, setProvisionContent1] = useState("");
  const [provisionContent2, setProvisionContent2] = useState("");

  useLayoutEffect(() => {
    let url = "/api/set/provision";
    axios.get(url).then((res) => {
      setProvisionContent1(res.data.terms[0].termsdescription);
      setProvisionContent2(res.data.terms[1].termsdescription);
    });
  }, []);

  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="left_wrapper">
            <div className="isMobile_right_column mbhide">
              <span
                onClick={() => openModal("modal1")}
                style={{ cursor: "pointer" }}
              >
                서비스 이용 약관
              </span>
              │
              <span
                onClick={() => openModal("modal2")}
                style={{ cursor: "pointer", fontWeight:"bold" }}
              >
                개인정보처리방침
              </span>
            </div>
            <div className="logo_column">
              <img src={footerLogo} alt="logo" />
            </div>
            <div className="center_column">
              <div className="center_column_wrapper">
                <div className="company">㈜리파인</div>
                <div className="right_column">
                  <span
                    onClick={() => openModal("modal1")}
                    style={{ cursor: "pointer" }}
                  >
                    서비스 이용 약관
                  </span>
                  │
                  <BrowserRouter forceRefresh={true}>
                    <Link to="/notice/board/terms/1">
                      <span style={{ cursor: "pointer", fontWeight:"bold" }}>
                        개인정보처리방침
                      </span>
                    </Link>
                  </BrowserRouter>

                </div>
              </div>
              <div className="company_info">
                <div>
                  서울특별시 강남구 테헤란로 625 덕명빌딩 4층 (삼성동 170-9)
                  │&nbsp;
                </div>
                <div>TEL. 02-6188-6676 │&nbsp;</div>
                <div>FAX. 02-565-5398</div>
              </div>
              <div className="company_info">
                <div>사업자등록번호: 120-86-40033 │&nbsp;</div>
                <div>대표이사: 이창섭 │&nbsp;</div>
                <div>이메일: support@zibfine.com</div>
              </div>
              <div className="copyright">
                Copyright © 2022 REFINE Co.,Ltd. All rights reserved
              </div>
            </div>
          </div>

          {children}
        </div>
      </footer>
      <Modal open={modal["modal1"]} width={500}>
        <div className="modal_inner_ct wide_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal1")}
          />
          <h2>서비스 이용약관</h2>
          <div className="article_column">
            <textarea
              value={provisionContent1 || ""}
              readOnly
              style={{ cursor: "default" }}
            ></textarea>
          </div>
        </div>
      </Modal>

      <Modal open={modal["modal2"]} width={500}>
        <div className="modal_inner_ct wide_modal">
          <img
            src={close}
            alt="close"
            className="close btn"
            onClick={() => closeModal("modal2")}
          />
          <h2>개인정보수집 및 이용 동의</h2>
          <div className="article_column">
            <textarea
              value={provisionContent2 || ""}
              readOnly
              style={{ cursor: "default" }}
            ></textarea>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Footer;
